@import '../../../node_modules/bulma/sass/utilities/initial-variables.sass';

.radio-selector__container {
  display: flex;
  flex-direction: column;

  hr {
    margin: 0;
  }
}

.selector-item {
  display: flex;
  gap: 1rem;
  padding: 1rem;

  &__radio {
    margin: 2px 8px 0px 8px;
  }

  &__body {
    flex: 1;
  }

  &__label {
    font-size: large;
    font-weight: bold;
  }

  &__content {
    margin-top: 0.5rem;
    color: $grey;
    font-size: small;
  }
}
