.notification {
  &-header {
    display: flex;

    &-icon {
      flex-shrink: 0;
    }

    &-message {
      padding-left: 1rem;
    }
  }
}
