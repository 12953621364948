.no-app {
  &-box {
    text-align: center;
    max-width: $element-max-width-narrow-er;
    margin: 0 auto;
    padding: 3rem;

    @include until($tablet) {
      padding: 2rem;
    }
  }

  &-title {
    font-size: $size-large;
    font-weight: $weight-semibold;

    padding: 0 0 2rem;
  }

  &-text {
    margin-bottom: 1rem;
  }

  &-browsers {
    margin-top: 2rem;

    &-link {
      display: inline-block;
      width: 3.5rem;
      height: 3.5rem;
      margin: 0.5rem;
    }
  }
}
