.shipping-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.back-button {
  display: flex;
  justify-content: space-between;
}

.shipping-selector-container {
  width: 100%;
  max-width: 500px;
}
