.continue-with-return {
  &-info {
    td {
      white-space: nowrap;
      padding: 2px 10px 2px 0;
    }

    .tag {
      &:not(:first-child) {
        margin-left: 6px;
      }

      > span:not(:first-child) {
        margin-left: 4px;
      }
    }

    span[data-tooltip] {
      border-bottom: 0;
      color: $info;
      margin-left: 6px;

      &:hover {
        color: $info-dark;
      }
    }
  }

  .notification {
    margin: -1rem -1rem 1rem;
  }
}
