$review-box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
$review-action-bar-spacing: 1rem;

.review {
  &-budbee {
    img {
      width: 64px;
      height: 64px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    h1 {
      font-weight: $weight-semibold;
      text-align: center;
      position: relative;
      padding: 0.75rem;
    }
    &-phonenumber {
      margin-top: 5;
    }
  }
  &-sendcloud {
    img {
      width: 64px;
      height: 64px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    h1 {
      font-weight: $weight-semibold;
      text-align: center;
      position: relative;
      padding: 0.75rem;
    }
    &-streetName {
      margin-top: 5px;
    }
    &-streetNumber {
      margin-top: 5px;
    }
  }

  &-block {
    background-color: $white-ter;

    &-wrap {
      margin-top: 2rem;
    }

    &-title {
      background-color: $white;
      box-shadow: $review-box-shadow;
      font-weight: $weight-semibold;
      text-align: center;
      position: relative;
      padding: 0.75rem;
      z-index: 1;
    }
  }

  &-refund {
    &-table {
      width: 100%;
      font-weight: $weight-semibold;
      text-align: left;

      th {
        padding: 1.5rem 1rem;

        &:last-child {
          text-align: right;
        }
      }

      td {
        border-bottom: 1px solid $border;
        color: $grey;
        padding: 1rem;

        &:last-child:not(:first-child) {
          text-align: right;
          white-space: nowrap;
        }

        &.is-notification-cell {
          padding-top: 0.25rem;
        }
      }

      tfoot {
        background-color: $white-bis;
        border-top: 1px solid $border;
      }
    }
  }

  &-address {
    text-align: center;
    padding: 1rem;

    &-block {
      background-color: $white;
      border: 1px dashed $border;
      padding: 1rem;
    }
  }

  &-action-bar {
    background-color: $white;
    box-shadow: $review-box-shadow;
    position: relative;
    padding: $review-action-bar-spacing;
    z-index: 1;

    &-buttons {
      display: flex;

      > button:last-child {
        margin-left: $review-action-bar-spacing;
      }
    }

    &-info {
      margin-top: $review-action-bar-spacing;
    }
  }

  &-comment {
    border-top: 1px solid $border;
    padding: 1rem 1rem 2rem;

    &-field {
      margin-bottom: 1rem;
    }
  }
}
