$color-icon: #3298dc;
$active-color: white;
$inactive-color: #84c1ea;
$timeline-tiny-mobile-breakpoint: 465px;
$timeline-mobile-breakpoint: 540px;

.timeline {
  display: flex;
  justify-content: space-evenly;

  &.timeline--lowfidelity {
    &:not(.timeline-element--first) {
      .timeline-element_icon::before {
        width: 116px;

        @media screen and (max-width: $timeline-tiny-mobile-breakpoint) {
          width: 140%;
        }
      }
    }
  }
}

.timeline-element {
  z-index: 2;

  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;

  width: 100px;

  font-size: 12px;

  svg {
    fill: $color-icon;
  }

  &:not(.timeline-element--first) {
    position: relative;

    .timeline-element_icon::before {
      z-index: 1;
      position: absolute;
      right: 85px;
      content: '';
      width: 42%;
      height: 8px;
    }
  }
}

.timeline-element--inactive {
  color: $inactive-color;
  z-index: 1;

  .timeline-element_icon {
    background-color: $inactive-color;
  }

  &:not(.timeline-element--first) {
    .timeline-element_icon::before {
      background-color: $inactive-color;
    }
  }
}

.timeline-element--active {
  color: $active-color;
  z-index: 2;

  .timeline-element_icon {
    background-color: $active-color;
  }

  &:not(.timeline-element--first) {
    .timeline-element_icon::before {
      background-color: $active-color;
    }
  }
}

.timeline-element_icon {
  width: 70px;
  height: 70px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;

  margin-bottom: 8px;

  & > svg {
    width: 35px;
  }
}

@media screen and (min-width: $timeline-tiny-mobile-breakpoint) and (max-width: $timeline-mobile-breakpoint) {
  .timeline-element {
    width: 80px;

    &:not(.timeline-element--first) {
      .timeline-element_icon::before {
        right: 68px;
        width: 62%;
      }
    }
  }

  .timeline-element_icon {
    width: 56px;
    height: 56px;

    & > svg {
      width: 28px;
    }
  }
}

@media screen and (max-width: $timeline-tiny-mobile-breakpoint) {
  .timeline-element {
    width: 20%;
    font-size: 9px;

    &:not(.timeline-element--first) {
      .timeline-element_icon::before {
        right: 80%;
        width: 60%;
        height: 6px;
      }
    }
  }

  .timeline-element_icon {
    width: 45px;
    height: 45px;

    & > svg {
      width: 28px;
    }
  }
}
