$icon-spacing: 4px;

.custom-link {
  display: inline-block;
  color: $link;
  cursor: pointer;

  &:hover {
    color: $link-hover;
  }

  > svg {
    &:first-child {
      margin-right: $icon-spacing;
    }

    &:last-child {
      margin-left: $icon-spacing;
    }
  }
}
