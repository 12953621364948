
// sass variables
$primary: var(--primary)
$product: var(--product)
$secondary: var(--secondary)


// declared base css variables
#{":root"}
  --primary: rgb(19, 127, 186)
  --product: rgb(255, 255, 255)
  --secondary: rgb(255, 176, 66)


// derived, generated css variables
#{":root"}
  --primary--500--lighten: rgb(21, 143, 209)
  --primary--90--rgba: rgba(19, 127, 186, 0.9)
  --primary--color-invert: rgb(255, 255, 255)
  --primary--dark-color: rgb(21, 139, 204)
  --primary--light-color: rgb(237, 247, 253)

  --product--500--lighten: rgb(255, 255, 255)
  --product--90--rgba: rgba(255, 255, 255, 0.9)
  --product--color-invert: rgba(0, 0, 0, 0.7)

  --secondary--500--lighten: rgb(255, 187, 92)
  --secondary--90--rgba: rgba(255, 176, 66, 0.9)
  --secondary--color-invert: rgba(0, 0, 0, 0.7)

