.page-not-found {
  &-box {
    text-align: center;
    max-width: $element-max-width-narrow;
    margin: 0 auto;
    padding: 3rem;
  }

  &-body {
    margin: 0 0 2rem;
  }
}
