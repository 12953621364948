.rma-statuses-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.download-label-invoice {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}
