@charset "utf-8";
@import './bulma-generated/generated-fallbacks.css'; // import fallbacks first, so they are overridden
@import './bulma-generated/generated-vars.sass';

// Bulma initial variables and utilities
@import '../../node_modules/bulma/sass/utilities/initial-variables.sass';

// Set $background to avoid error - see https://github.com/jgthms/bulma/issues/3287
$background: $white-ter !default;

// Set new color variables for our theme
// The color doesn't matter here, it's just the variable that needs to be defined so it can be properly mapped.
// The actual default color is being set in bulma-css-vars.config.js and updated later from the db.
$background: $white-ter !default;
$secondary: #000 !default;
$product: #fff !default;

// Override and patch bulma functions (css vars)
@import '../../node_modules/bulma-css-vars/functions.sass';
@import '../../node_modules/bulma-css-vars/derived-variables.sass';

// Bulma derived variables overrides and add custom variables to color map (to be used as classes)
//// Fonts
$family-primary: var(--app-font-family--primary);
$family-secondary: var(--app-font-family--secondary);

//// Colors
$secondary-invert: findColorInvert($secondary);
$product-invert: findColorInvert($product);

$addColors: (
  'secondary': (
    $secondary,
    $secondary-invert,
  ),
  'product': (
    $product,
    $product-invert,
  ),
);
$colors: map-merge($colors, $addColors);

// Rest of Bulma utilities
@import '../../node_modules/bulma/sass/utilities/mixins.sass';
@import '../../node_modules/bulma/sass/utilities/controls.sass';
@import '../../node_modules/bulma/sass/utilities/extends.sass';

// Bulma variables overrides
$column-gap: 0.5rem;
$modal-content-width: 920px;
$box-radius: 0;

// Bulma rest
@import '../../node_modules/bulma/sass/base/_all.sass';
@import '../../node_modules/bulma/sass/grid/columns.sass';
@import '../../node_modules/bulma/sass/components/card.sass';
@import '../../node_modules/bulma/sass/components/level.sass';
@import '../../node_modules/bulma/sass/components/modal.sass';
@import '../../node_modules/bulma/sass/elements/box.sass';
@import '../../node_modules/bulma/sass/elements/button.sass';
@import '../../node_modules/bulma/sass/elements/container.sass';
@import '../../node_modules/bulma/sass/elements/image.sass';
@import '../../node_modules/bulma/sass/elements/notification.sass';
@import '../../node_modules/bulma/sass/elements/progress.sass';
@import '../../node_modules/bulma/sass/elements/tag.sass';
@import '../../node_modules/bulma/sass/elements/title.sass';
@import '../../node_modules/bulma/sass/form/_all.sass';
@import '../../node_modules/bulma/sass/layout/section.sass';
@import '../../node_modules/bulma/sass/helpers/spacing.sass';
@import '../../node_modules/bulma/sass/helpers/typography.sass';

// Custom variables
$text-color-light: $white;
$border-color-highlight: $dark;

$element-max-width-narrow: 520px;
$element-max-width-narrow-er: 460px;

// General layout
@import './layout/general.scss';
@import './layout/helpers.scss';

// Bulma plugins
@import '../../node_modules/@creativebulma/bulma-tooltip/src/sass/index.sass';

// Components
@import './components/continue-with-return.scss';
@import './components/custom-button.scss';
@import './components/custom-link.scss';
@import './components/custom-loader.scss';
@import './components/footer.scss';
@import './components/item-card.scss';
@import './components/item-wizard.scss';
@import './components/items-list.scss';
@import './components/no-app.scss';
@import './components/notification.scss';
@import './components/order-lookup.scss';
@import './components/page-not-found.scss';
@import './components/sign-out.scss';
@import './components/summary.scss';
@import './components/review.scss';
@import './components/quickselect.scss';
