$item-card-content-padding: 1rem;
$wiz-product-background: $product;

.item-card {
  display: flex;
  align-items: center;
  position: relative;

  &:not(.is-review-card):not(.is-delivery-card) {
    border: 2px solid $card-background-color;
    transition: border 0.2s, -webkit-transform 0.2s;
  }

  &.is-selectable:not(.is-selected):hover {
    border-color: $border-color-highlight;
    cursor: pointer;
  }

  &:not(:first-child):not(.is-review-card):not(.is-delivery-card) {
    margin-top: 1rem;
  }

  &.is-review-card {
    background-color: $white-ter;

    &:not(:first-child) {
      border-top: 1px solid $border;
    }
  }
  &.is-delivery-card {
    background-color: $white-ter;
    padding: 1rem;

    label span {
      margin-left: 0.5rem;
      color: $grey-dark;
    }
  }

  &.is-disabled {
    opacity: 0.7;
  }

  &-image {
    display: flex;
    flex-shrink: 0;
    background-color: $white;
    width: 120px;
    height: 120px;
    background-color: $wiz-product-background;

    &-review {
      border-right: 1px solid $border;
    }

    > img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      margin: auto;
    }

    > svg {
      opacity: 0.15;
      width: 100% !important;
      height: 60%;
      margin: auto;
    }
  }

  &-content {
    flex-grow: 1;
    padding: $item-card-content-padding;
  }

  &-title {
    font-weight: $weight-semibold;
  }

  &-variant {
    color: $grey;
  }

  &-price {
    &-discount {
      color: $grey-light;
      text-decoration: line-through;
      white-space: nowrap;
    }

    &-review {
      font-weight: $weight-semibold;
      padding-right: $item-card-content-padding;
      text-align: right;
      white-space: nowrap;

      > span {
        display: block;
      }
    }

    span[data-tooltip] {
      border-bottom: 0;
      color: $grey;

      &:hover {
        color: $grey-dark;
      }
    }
  }

  &-higher-price-icon {
    margin-left: 10px;
  }

  &-adjustment-warning {
    display: flex;
    gap: 10px;

    &-tooltip {
      display: flex;
      align-items: center;
    }
  }

  &-selected {
    background-color: $primary;
    color: $primary-invert;
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 0;
    left: 0;

    &-icon {
      @include center($size-normal);
    }
  }

  &-deselect {
    display: flex;
    align-self: stretch;
    align-items: center;
    justify-content: center;
    background-color: $white-ter;
    border-left: 1px solid $border;
    color: $primary;
    color: $grey-light;
    font-size: $size-large;
    margin: -2px;
    width: 64px;
    cursor: pointer;
    transition: border 0.2s, color 0.2s, -webkit-transform 0.2s;

    &:hover {
      border-color: darken($border, 15%);
      color: darken($grey, 15%);
    }
  }
}
