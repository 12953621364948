.sign-out {
  margin-top: 1rem;
  
  text-align: center;

  button.is-link {
    white-space: normal;
  }

  .notification & {
    margin-top: 1rem;
  }
}
