@keyframes spinAroundCustom {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.custom-loader {
  padding: 5rem;

  &.is-loading {
    position: relative;
    pointer-events: none;
    opacity: 0.5;

    &::after {
      animation: spinAroundCustom 500ms infinite linear;
      border: 2px solid $grey-lighter;
      border-radius: $radius-rounded;
      border-right-color: transparent;
      border-top-color: transparent;
      content: '';
      display: block;

      position: absolute;
      top: calc(50% - 2.5em);
      left: calc(50% - 2.5em);
      width: 5em;
      height: 5em;
      border-width: 0.25em;
    }
  }

  &.is-center-page {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
