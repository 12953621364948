html {
  background-color: $background;
  background-position: top center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  // Overlay for darkening background image
  &::before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1000;
  }
}

.app {
  &-container {
    width: auto;
    padding: 1rem;
    margin: 0 auto;
  }

  &-logo {
    text-align: center;
    width: 100%;
    max-width: 420px;
    padding: 3rem 0;
    margin: 0 auto;

    img {
      max-width: 100%;

      @include until($tablet) {
        max-height: 150px;
      }
    }
  }
}
