$wiz-background-a: $white-bis;
$wiz-background-b: $white-ter;
$wiz-product-background: $product;
$wiz-border: 1px solid $border;
$wiz-padding-image: 1.5rem;
$wiz-padding-content: 1rem;
$wiz-spacing-option: 0.75rem;
$wiz-footer-padding: 4px 2px;
$wiz-mobile-breakpoint: $tablet;
$wiz-mobile-header-img-size: 3.4rem;
$wiz-mobile-header-padding: $wiz-padding-content ($wiz-padding-content * 2) $wiz-padding-content
  (($wiz-padding-content * 2) + $wiz-mobile-header-img-size);
$wiz-mobile-close-size: 48px;

@mixin has-parentheses($distance) {
  position: relative;
  &:before,
  &:after {
    position: absolute;
  }
  &:before {
    content: '(';
    left: $distance;
  }
  &:after {
    content: ')';
    right: $distance;
  }
}

.item-wizard {
  display: flex;
  background-color: $white;
  width: 100%;

  &-modal {
    > .modal-content {
      display: flex;
      width: calc(100% - 4rem);
      max-width: 1080px;
      max-height: calc(100% - 8rem);
    }
  }

  &-image {
    display: flex;
    flex: 1 1 auto;
    text-align: center;
    width: 60%;
    height: 100%;
    padding: $wiz-padding-image;
    background-color: $wiz-product-background;

    > img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      margin: auto;
    }

    > svg {
      opacity: 0.15;
      width: 100% !important;
      height: 60%;
      margin: auto;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    box-shadow: -1px 0 10px rgba(0, 0, 0, 0.2);
    width: 40%;
    min-width: 400px;

    &-header {
      background-color: $wiz-background-a;
      border-bottom: $wiz-border;
      padding: $wiz-padding-content;

      &-title {
        font-size: $size-large;
        font-weight: $weight-semibold;
      }

      &-subtitle {
        color: $grey;

        &-separator {
          margin: 0 4px;
        }

        &-discount {
          color: $grey-light;
          text-decoration: line-through;
        }
      }
    }

    &-subheader {
      background-color: $wiz-background-b;
      border-bottom: $wiz-border;
      padding: $wiz-padding-content;

      &-title {
        font-size: $size-large;
        font-weight: $weight-semibold;
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: $wiz-background-a;
      flex: 1 1 auto;
      overflow: auto;
      padding: $wiz-padding-content;

      > .notification {
        margin-bottom: 1rem;

        &:not(:first-child) {
          margin-top: $wiz-spacing-option;
        }
      }
    }

    &-footer {
      background-color: $wiz-background-a;
      border-top: $wiz-border;
      padding: $wiz-padding-content;
    }
  }

  &-option {
    height: auto;
    padding: $wiz-spacing-option;

    &:not(:first-child) {
      margin-top: $wiz-spacing-option;
    }

    > div:first-child {
      flex: 1 1 auto;
      text-align: left;
      white-space: normal;
      padding-right: 8px;

      &.is-loading:after {
        @include loader;
        display: inline-block;
        vertical-align: middle;
        margin-left: 6px;
      }

      > .tag {
        margin-left: 10px;
      }
    }

    &-price {
      color: $grey;
      font-size: $size-small;
      padding-right: 6px;
    }

    &-add-to-price {
      margin-right: 6px;
      &.has-parentheses {
        @include has-parentheses(0.25em);
      }
    }

    &-texts {
      display: flex;
      align-items: flex-start;
      white-space: nowrap;
    }

    &-subtext {
      color: $grey;
      font-size: $size-small;
    }
  }

  .more-options-button {
    margin: 1rem 0;
  }

  &-card {
    display: flex;
    flex-direction: column;
    border: 2px solid $card-background-color;
    height: 100%;
    transition: border 0.2s, -webkit-transform 0.2s;

    &:not(.is-disabled):hover {
      border-color: $border-color-highlight;
      cursor: pointer;
    }

    &.is-disabled::before {
      content: '';
      display: block;
      background-color: $white-ter;
      opacity: 0.75;
      position: absolute;
      top: -2px;
      right: -2px;
      bottom: -2px;
      left: -2px;
      z-index: 1;
    }

    &-image {
      display: flex;
      flex-grow: 1;
      position: relative;
      padding: 0.25rem;

      > img {
        align-self: flex-start;
      }

      > svg {
        opacity: 0.15;
        width: 100% !important;
        height: 60%;
        margin: auto;
      }
    }

    &-outofstock {
      align-self: center;
      text-align: center;
      opacity: 0.5;
      width: 100%;
      position: absolute;
      left: 0;
      z-index: 2;
    }

    &-footer {
      background-color: $white-bis;
      font-size: $size-small;
      line-height: 1.25;
      text-align: center;
    }

    &-row {
      border-top: 1px dashed $border-light;
      font-weight: $weight-semibold;
      padding: $wiz-footer-padding;
    }

    &-options {
      color: $grey;
    }

    &-price {
      &.is-too-high {
        text-decoration: line-through;
      }

      .tag {
        font-weight: normal;
        height: 18px;
        margin-left: 4px;
        @include has-parentheses(0.25em);
      }
    }
  }

  &-comment {
    &-header {
      background-color: $wiz-background-a;
      font-weight: $weight-semibold;
      padding: $wiz-padding-content $wiz-padding-content 0 $wiz-padding-content;
    }

    &-field {
      height: 100%;
    }
  }

  &-collapsible {
    &-icon {
      transition: all 0.4s ease;
    }

    &-body {
      padding: 1rem 0 0.5rem;
    }
  }
}

@media screen and (max-width: $wiz-mobile-breakpoint) {
  .item-wizard {
    display: block;
    position: relative;

    &-modal {
      > .modal-content {
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
      }

      > .modal-close {
        height: $wiz-mobile-close-size;
        max-height: $wiz-mobile-close-size;
        max-width: $wiz-mobile-close-size;
        min-height: $wiz-mobile-close-size;
        min-width: $wiz-mobile-close-size;
        width: $wiz-mobile-close-size;
        top: 8px;
        right: 8px;

        &::before,
        &::after {
          background-color: $dark;
        }
      }
    }

    &-image {
      border: 1px solid $border;
      height: 3.4rem;
      width: 3.4rem;
      position: absolute;
      top: $wiz-padding-content;
      left: $wiz-padding-content;
      padding: 0;
      z-index: 1;
    }

    &-content {
      height: 100%;
      width: 100%;
      min-width: auto;
      min-width: initial; // fallback

      &-header {
        padding: $wiz-mobile-header-padding;

        &-title {
          font-size: $size-medium;
        }
      }
    }
  }
}
