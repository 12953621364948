.button.is-primary {
  background-color: rgb(19, 127, 186);
  color: rgb(255, 255, 255);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-primary:hover,
.button.is-primary.is-hovered {
  background-color: rgb(19, 127, 186);
  color: rgb(255, 255, 255);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-primary:focus,
.button.is-primary.is-focused {
  color: rgb(255, 255, 255);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-primary:active,
.button.is-primary.is-active {
  background-color: rgb(19, 127, 186);
  color: rgb(255, 255, 255);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-primary[disabled],
fieldset[disabled] .button.is-primary {
  background-color: rgb(19, 127, 186);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-primary.is-inverted {
  background-color: rgb(255, 255, 255);
  color: rgb(19, 127, 186);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-primary.is-inverted:hover,
.button.is-primary.is-inverted.is-hovered {
  background-color: rgb(255, 255, 255);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-primary.is-inverted[disabled],
fieldset[disabled] .button.is-primary.is-inverted {
  background-color: rgb(255, 255, 255);
  color: rgb(19, 127, 186);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-primary.is-outlined {
  border-color: rgb(19, 127, 186);
  color: rgb(19, 127, 186);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-primary.is-outlined:hover,
.button.is-primary.is-outlined.is-hovered,
.button.is-primary.is-outlined:focus,
.button.is-primary.is-outlined.is-focused {
  background-color: rgb(19, 127, 186);
  border-color: rgb(19, 127, 186);
  color: rgb(255, 255, 255);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-primary.is-outlined[disabled],
fieldset[disabled] .button.is-primary.is-outlined {
  border-color: rgb(19, 127, 186);
  color: rgb(19, 127, 186);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-primary.is-inverted.is-outlined {
  border-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-primary.is-inverted.is-outlined:hover,
.button.is-primary.is-inverted.is-outlined.is-hovered,
.button.is-primary.is-inverted.is-outlined:focus,
.button.is-primary.is-inverted.is-outlined.is-focused {
  background-color: rgb(255, 255, 255);
  color: rgb(19, 127, 186);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-primary.is-inverted.is-outlined[disabled],
fieldset[disabled] .button.is-primary.is-inverted.is-outlined {
  border-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-primary.is-light {
  background-color: rgb(237, 247, 253);
  color: rgb(21, 139, 204);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-primary.is-light:hover,
.button.is-primary.is-light.is-hovered {
  background-color: rgb(237, 247, 253);
  color: rgb(21, 139, 204);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-primary.is-light:active,
.button.is-primary.is-light.is-active {
  background-color: rgb(237, 247, 253);
  color: rgb(21, 139, 204);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-secondary {
  background-color: rgb(255, 176, 66);
  color: rgba(0, 0, 0, 0.7);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-secondary:hover,
.button.is-secondary.is-hovered {
  background-color: rgb(255, 176, 66);
  color: rgba(0, 0, 0, 0.7);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-secondary:focus,
.button.is-secondary.is-focused {
  color: rgba(0, 0, 0, 0.7);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-secondary:active,
.button.is-secondary.is-active {
  background-color: rgb(255, 176, 66);
  color: rgba(0, 0, 0, 0.7);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-secondary[disabled],
fieldset[disabled] .button.is-secondary {
  background-color: rgb(255, 176, 66);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-secondary.is-inverted {
  background-color: rgba(0, 0, 0, 0.7);
  color: rgb(255, 176, 66);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-secondary.is-inverted:hover,
.button.is-secondary.is-inverted.is-hovered {
  background-color: rgba(0, 0, 0, 0.7);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-secondary.is-inverted[disabled],
fieldset[disabled] .button.is-secondary.is-inverted {
  background-color: rgba(0, 0, 0, 0.7);
  color: rgb(255, 176, 66);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-secondary.is-outlined {
  border-color: rgb(255, 176, 66);
  color: rgb(255, 176, 66);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-secondary.is-outlined:hover,
.button.is-secondary.is-outlined.is-hovered,
.button.is-secondary.is-outlined:focus,
.button.is-secondary.is-outlined.is-focused {
  background-color: rgb(255, 176, 66);
  border-color: rgb(255, 176, 66);
  color: rgba(0, 0, 0, 0.7);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-secondary.is-outlined[disabled],
fieldset[disabled] .button.is-secondary.is-outlined {
  border-color: rgb(255, 176, 66);
  color: rgb(255, 176, 66);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-secondary.is-inverted.is-outlined {
  border-color: rgba(0, 0, 0, 0.7);
  color: rgba(0, 0, 0, 0.7);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-secondary.is-inverted.is-outlined:hover,
.button.is-secondary.is-inverted.is-outlined.is-hovered,
.button.is-secondary.is-inverted.is-outlined:focus,
.button.is-secondary.is-inverted.is-outlined.is-focused {
  background-color: rgba(0, 0, 0, 0.7);
  color: rgb(255, 176, 66);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-secondary.is-inverted.is-outlined[disabled],
fieldset[disabled] .button.is-secondary.is-inverted.is-outlined {
  border-color: rgba(0, 0, 0, 0.7);
  color: rgba(0, 0, 0, 0.7);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-product {
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.7);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-product:hover,
.button.is-product.is-hovered {
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.7);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-product:focus,
.button.is-product.is-focused {
  color: rgba(0, 0, 0, 0.7);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-product:active,
.button.is-product.is-active {
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.7);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-product[disabled],
fieldset[disabled] .button.is-product {
  background-color: rgb(255, 255, 255);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-product.is-inverted {
  background-color: rgba(0, 0, 0, 0.7);
  color: rgb(255, 255, 255);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-product.is-inverted:hover,
.button.is-product.is-inverted.is-hovered {
  background-color: rgba(0, 0, 0, 0.7);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-product.is-inverted[disabled],
fieldset[disabled] .button.is-product.is-inverted {
  background-color: rgba(0, 0, 0, 0.7);
  color: rgb(255, 255, 255);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-product.is-outlined {
  border-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-product.is-outlined:hover,
.button.is-product.is-outlined.is-hovered,
.button.is-product.is-outlined:focus,
.button.is-product.is-outlined.is-focused {
  background-color: rgb(255, 255, 255);
  border-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.7);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-product.is-outlined[disabled],
fieldset[disabled] .button.is-product.is-outlined {
  border-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-product.is-inverted.is-outlined {
  border-color: rgba(0, 0, 0, 0.7);
  color: rgba(0, 0, 0, 0.7);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-product.is-inverted.is-outlined:hover,
.button.is-product.is-inverted.is-outlined.is-hovered,
.button.is-product.is-inverted.is-outlined:focus,
.button.is-product.is-inverted.is-outlined.is-focused {
  background-color: rgba(0, 0, 0, 0.7);
  color: rgb(255, 255, 255);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.button.is-product.is-inverted.is-outlined[disabled],
fieldset[disabled] .button.is-product.is-inverted.is-outlined {
  border-color: rgba(0, 0, 0, 0.7);
  color: rgba(0, 0, 0, 0.7);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.notification.is-primary {
  background-color: rgb(19, 127, 186);
  color: rgb(255, 255, 255);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.notification.is-primary.is-light {
  background-color: rgb(237, 247, 253);
  color: rgb(21, 139, 204);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.notification.is-secondary {
  background-color: rgb(255, 176, 66);
  color: rgba(0, 0, 0, 0.7);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.notification.is-product {
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.7);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.progress.is-primary::-webkit-progress-value {
  background-color: rgb(19, 127, 186);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.progress.is-primary::-moz-progress-bar {
  background-color: rgb(19, 127, 186);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.progress.is-primary::-ms-fill {
  background-color: rgb(19, 127, 186);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.progress.is-secondary::-webkit-progress-value {
  background-color: rgb(255, 176, 66);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.progress.is-secondary::-moz-progress-bar {
  background-color: rgb(255, 176, 66);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.progress.is-secondary::-ms-fill {
  background-color: rgb(255, 176, 66);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.progress.is-product::-webkit-progress-value {
  background-color: rgb(255, 255, 255);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.progress.is-product::-moz-progress-bar {
  background-color: rgb(255, 255, 255);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.progress.is-product::-ms-fill {
  background-color: rgb(255, 255, 255);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.tag:not(body).is-primary {
  background-color: rgb(19, 127, 186);
  color: rgb(255, 255, 255);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.tag:not(body).is-primary.is-light {
  background-color: rgb(237, 247, 253);
  color: rgb(21, 139, 204);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.tag:not(body).is-secondary {
  background-color: rgb(255, 176, 66);
  color: rgba(0, 0, 0, 0.7);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.tag:not(body).is-product {
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.7);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.is-primary.textarea,
.is-primary.input {
  border-color: rgb(19, 127, 186);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.is-secondary.textarea,
.is-secondary.input {
  border-color: rgb(255, 176, 66);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.is-product.textarea,
.is-product.input {
  border-color: rgb(255, 255, 255);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.select.is-primary:not(:hover)::after {
  border-color: rgb(19, 127, 186);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.select.is-primary select {
  border-color: rgb(19, 127, 186);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.select.is-primary select:hover,
.select.is-primary select.is-hovered {
  border-color: rgb(19, 127, 186);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.select.is-secondary:not(:hover)::after {
  border-color: rgb(255, 176, 66);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.select.is-secondary select {
  border-color: rgb(255, 176, 66);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.select.is-secondary select:hover,
.select.is-secondary select.is-hovered {
  border-color: rgb(255, 176, 66);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.select.is-product:not(:hover)::after {
  border-color: rgb(255, 255, 255);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.select.is-product select {
  border-color: rgb(255, 255, 255);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.select.is-product select:hover,
.select.is-product select.is-hovered {
  border-color: rgb(255, 255, 255);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.file.is-primary .file-cta {
  background-color: rgb(19, 127, 186);
  color: rgb(255, 255, 255);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.file.is-primary:hover .file-cta,
.file.is-primary.is-hovered .file-cta {
  background-color: rgb(19, 127, 186);
  color: rgb(255, 255, 255);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.file.is-primary:focus .file-cta,
.file.is-primary.is-focused .file-cta {
  color: rgb(255, 255, 255);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.file.is-primary:active .file-cta,
.file.is-primary.is-active .file-cta {
  background-color: rgb(19, 127, 186);
  color: rgb(255, 255, 255);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.file.is-secondary .file-cta {
  background-color: rgb(255, 176, 66);
  color: rgba(0, 0, 0, 0.7);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.file.is-secondary:hover .file-cta,
.file.is-secondary.is-hovered .file-cta {
  background-color: rgb(255, 176, 66);
  color: rgba(0, 0, 0, 0.7);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.file.is-secondary:focus .file-cta,
.file.is-secondary.is-focused .file-cta {
  color: rgba(0, 0, 0, 0.7);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.file.is-secondary:active .file-cta,
.file.is-secondary.is-active .file-cta {
  background-color: rgb(255, 176, 66);
  color: rgba(0, 0, 0, 0.7);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.file.is-product .file-cta {
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.7);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.file.is-product:hover .file-cta,
.file.is-product.is-hovered .file-cta {
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.7);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.file.is-product:focus .file-cta,
.file.is-product.is-focused .file-cta {
  color: rgba(0, 0, 0, 0.7);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.file.is-product:active .file-cta,
.file.is-product.is-active .file-cta {
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.7);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.help.is-primary {
  color: rgb(19, 127, 186);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.help.is-secondary {
  color: rgb(255, 176, 66);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.help.is-product {
  color: rgb(255, 255, 255);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

[data-tooltip]:not(.is-loading).has-tooltip-primary:before,
[data-tooltip]:not(.is-disabled).has-tooltip-primary:before,
[data-tooltip]:not([disabled]).has-tooltip-primary:before {
  background-color: rgba(19, 127, 186, 0.9);
  color: rgb(255, 255, 255);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

[data-tooltip]:not(.is-loading).has-tooltip-secondary:before,
[data-tooltip]:not(.is-disabled).has-tooltip-secondary:before,
[data-tooltip]:not([disabled]).has-tooltip-secondary:before {
  background-color: rgba(255, 176, 66, 0.9);
  color: rgba(0, 0, 0, 0.7);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

[data-tooltip]:not(.is-loading).has-tooltip-product:before,
[data-tooltip]:not(.is-disabled).has-tooltip-product:before,
[data-tooltip]:not([disabled]).has-tooltip-product:before {
  background-color: rgba(255, 255, 255, 0.9);
  color: rgba(0, 0, 0, 0.7);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

span[data-tooltip].has-tooltip-primary {
  border-bottom-color: rgb(21, 143, 209);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

span[data-tooltip].has-tooltip-secondary {
  border-bottom-color: rgb(255, 187, 92);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

span[data-tooltip].has-tooltip-product {
  border-bottom-color: rgb(255, 255, 255);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.item-card-image {
  background-color: rgb(255, 255, 255);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.item-card-selected {
  background-color: rgb(19, 127, 186);
  color: rgb(255, 255, 255);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.item-card-deselect {
  color: rgb(19, 127, 186);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.item-wizard-image {
  background-color: rgb(255, 255, 255);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.summary-howto-returntime {
  background-color: rgb(19, 127, 186);
  color: rgb(255, 255, 255);
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}