.order-lookup {
  &-box {
    max-width: $element-max-width-narrow-er;
    margin: 0 auto;
    padding: 3rem;

    @include until($tablet) {
      padding: 2rem;
    }
  }

  &-title {
    font-size: $size-large;
    font-weight: $weight-semibold;
    text-align: center;
    padding: 0 0 2rem;
  }
}
