$summary-box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);

.summary-email-to {
  margin: 1rem;
}

.summary {
  &-block {
    &-wrap {
      margin-top: 2rem;
    }

    &-title {
      background-color: $white;
      box-shadow: $summary-box-shadow;
      font-weight: $weight-semibold;
      text-align: center;
      position: relative;
      padding: 0.75rem;
      z-index: 1;  
    }
  }

  &-action-bar {
    background-color: $white;
    box-shadow: $summary-box-shadow;
    text-align: center;
    position: relative;
    padding: 2rem;
    z-index: 1;

    &-title {
      font-size: $size-medium;
      font-weight: $weight-semibold;
      margin-bottom: 2rem;
    }
  }

  &-email-to {
    font-size: $size-small;
    font-weight: $weight-semibold;
    color: $grey-light;

    span {
      color: $grey;
    }
  }

  &-howto {
    background-color: $white-ter;
    padding: 2rem 2rem 2rem;

    &-returntime {
      background-color: $primary;
      color: $primary-invert;
      font-size: $size-small;
      padding: 1rem 1rem 1rem;
      margin-bottom: 1.5rem;

      &-icon {
        float: left;
      }

      &-text {
        padding-left: 1rem;
      }
    }

    &-title {
      font-size: $size-medium;
      font-weight: $weight-semibold;
      text-align: center;
      margin-bottom: 1.5rem;
    }

    &-list {
      margin: 0 3rem;

      &:not(:last-child) {
        padding-bottom: 1.5rem;
      }

      > li {
        padding-left: 1rem;
        margin-top: 1rem;
      }
    }

    &-country {
      &-title {
        border-bottom: 1px dashed $border;
        font-weight: $weight-semibold;
        text-align: center;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
      }

      &-content {
        ul {
          list-style-type: disc;
        }

        ul,
        ol {
          //list-style-position: inside;
          margin: 1rem 0 1rem 1rem;

          > li {
            padding-left: 1rem;
            margin-top: 0.5rem;
          }
        }
      }
    }
  }
}
