.footer {
  color: $text-color-light;
  text-align: center;
  max-width: 420px;
  margin: 4rem auto;
  font-family: $family-secondary;

  &.has-expanded-margin {
    margin-bottom: 14rem;

    @include until($tablet) {
      margin-bottom: 20rem;
    }
  }

  .additional-links {
    margin-top: 1rem;
  }
  a {
    color: #ffffff;
  }
}
