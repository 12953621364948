$custom-button-padding: 0.75em;

.button.custom-button {
  height: auto;
  padding-bottom: calc(#{$custom-button-padding} - 1px);
  padding-top: calc(#{$custom-button-padding} - 1px);

  &.has-icon {
    > span {
      position: relative;
      top: -1px;

      &:first-child {
        margin-right: $custom-button-padding;
      }

      &:last-child {
        margin-left: $custom-button-padding;
      }
    }
  }

  &.is-link {
    background: none;
    padding: 0;

    &:hover {
      text-decoration: underline;
    }
  }
}
