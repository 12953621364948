@import '../../../node_modules/bulma/sass/utilities/initial-variables.sass';

.bottom-bar {
  background-color: $white-ter;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  box-shadow: 0 -3px 10px rgba(0, 0, 0, 0.4);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.6rem;
  z-index: 9;

  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
    gap: 1rem;

    &__item.left {
      grid-column: 1;
      grid-row: 3;
    }
  }

  &__item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
