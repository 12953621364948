.items-list {
  max-width: $element-max-width-narrow;
  margin: 0 auto;

  &-title {
    color: $text-color-light;
    font-size: $size-large;
    font-weight: $weight-semibold;
    text-align: center;

    &.summary {
      margin-bottom: 1.5rem;
    }
  }

  &-subtitle {
    color: $text-color-light;
    font-weight: $weight-semibold;
    text-align: center;
    margin: 1rem 0 2rem;

    &.is-ineligible {
      font-size: $size-normal;
      opacity: 0.7;
      margin: 3rem 0 0;
    }
  }
}

.rma-info-data {
  font-weight: $weight-semibold;
}

.shipping-label-paid {
  margin: 2rem 0 0;
}
